.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 80px;
    margin-top: 100px;
  }
  input {
    border: none;
    border-bottom: 2px solid #bdbdbd;
    width: 250px;
    margin-top: 5px;
    margin-bottom: 60px;
    padding: 5px 10px;
  }

  label {
    display: block;
    color: grey;
  }

  input:focus {
    outline: none;
  }
}

