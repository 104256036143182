.info {
  margin-top: 100px;

  p {
    margin-bottom: 50px;
  }
}

.table {
  margin: 0 auto;
  direction: rtl;
  font-size: 12px;
  border-collapse: collapse;

  td,
  th {
    padding: 10px 50px;
  }

  th {
    font-weight: normal;
    color: #3496f1;
  }

  tr,
  td {
    border-bottom: 1px solid #bdbdbd;
  }
}

.button {
  background-color: #bdbdbd;
  color: black;
  font-size: 14px;
  margin-top: 50px;
}

.button:hover {
  background-color: #939393;
}
