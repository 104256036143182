.navbar {
  display: flex;
  flex-direction: row-reverse;
  button {
    background-color: #bdbdbd;
    color: black;
    margin-top: 10px;
    margin-right: 20px;
  }
}

.navbar button:hover {
  background-color: #939393;
}
