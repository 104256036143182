.tracker {
  p {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  input {
    margin-bottom: 20px;
  }

  select {
    width: 250px;
    border: none;
    border-bottom: 2px solid #bdbdbd;
  }

  select:focus {
    border: none;
    border-bottom: 2px solid #bdbdbd;
  }

  textarea {
    width: 255px;
    height: 100px;
    margin-bottom: 20px;
    border-radius: 4px;
    resize: none;
    padding: 10px;
    border: 2px solid #bdbdbd;
    margin-top: 5px;
  }

  textarea:focus {
    outline: none;
  }
}
