.button {
  background-color: #3496f1;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
}

.button:hover {
  background-color: #006bfc;
}
